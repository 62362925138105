import { FC } from 'react';
import classNames from 'classnames';

import { useStyles } from './UserEvalBlockWithMark.styles';
import { EvaluationMark } from '@modules/EvaluationModule/enums/EvaluationMark';
import { EvaluationMarkDetails } from '@modules/EvaluationModule/interfaces/EvaluationMarkDetails';
import { RockyAvatar } from '@shared/components/RockyAvatar';
import { RockyTooltip } from '@shared/components/RockyTooltip';
import { UserInfo } from '@shared/interfaces/UserInfo';
import { Typography } from '@mui/material';

interface Props {
  question: EvaluationMarkDetails;
  user: UserInfo;
}

export const UserEvalBlockWithMark: FC<Props> = ({ question, user }) => {
  const styles = useStyles();
  const evaluatorImg = (
    <RockyTooltip
      trigger={<RockyAvatar fullName={user.name} small imageUrl={user.imageUrl} />}
      className={styles.tooltip}
    >
      {user.name}
    </RockyTooltip>
  );
  const toSentenceCase = (str: string): string => str.charAt(0).toUpperCase() + str.slice(1);

  if (!question.mark && !question.notes) {
    return <></>;
  }

  return (
    <div className={classNames(styles.root, { [styles.notCommented]: !question.notes })}>
      {evaluatorImg}
      <div className={styles.markContent}>
        {question.mark && (
          <Typography
            variant="body1"
            color="text.secondary"
            className={classNames(styles.header, {
              [styles.needDev]: question.mark === EvaluationMark.NeedsDevelopment,
            })}
          >
            {toSentenceCase(question.mark)}
          </Typography>
        )}
        <div className={styles.markNotes}>{question.notes}</div>
      </div>
    </div>
  );
};
