import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    '& [class*=notesAndGoals] [class*=blockTitle] ': {
      fontSize: 14,
    },
  },
  table: {
    display: 'flex',
    columnGap: 8,
    width: '100%',
    marginBottom: 32,
  },
  tableDataBlock: {
    display: 'flex',
    columnGap: 8,
    overflowX: 'auto',
    whiteSpace: 'nowrap',
    flexWrap: 'nowrap',
    width: '100%',
  },
  headerItem: {
    height: 90,
  },
  column: {
    flex: '0 0 auto',
    width: 130,
    display: 'flex',
    flexDirection: 'column',
    rowGap: 20,
  },
  columnItem: {
    height: 20,
    display: 'flex',
    alignItems: 'center',
  },
  question: {
    width: '100%',
    fontSize: 13,
    color: COLORS.TEXT.PRIMARY,
    lineHeight: '120%',
  },
  feedbackMarkIndicator: {
    width: '100%',
    height: 8,
    borderRadius: 8,
    position: 'relative',
  },
  evalNotesSkeleton: {
    boxSizing: 'border-box',
    width: 'calc(100% - 40px)',
    height: 80,
    margin: '10px 10px 10px 24px',
  },
  notes: {
    marginTop: 'auto',
  },
  loaderHolder: {
    width: '100%',
    display: 'flex',
    paddingTop: 24,
    alignItems: 'center',
    justifyContent: 'center',
  },
  comment: {
    position: 'absolute',
    transform: 'translateY(-80%)',
  },
  noPromotions: {
    color: COLORS.TEXT.SECONDARY,
    background: COLORS.HIGHLIGHTS.NEUTRAL,
    borderRadius: 8,
    padding: '16px',
    margin: '0 0 0 -16px',
  },
});
