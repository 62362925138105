import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    color: COLORS.TEXT.PRIMARY,
    height: 'fit-content',
    width: '100%',
    display: 'flex',
    alignItems: 'start',
    columnGap: 8,
  },
  header: {},
  notCommented: {
    color: COLORS.TEXT.SECONDARY,
    flexBasis: 'content',
    marginRight: '24px',
  },
  needDev: {
    color: COLORS.STATUS.WARNING,
  },
  markContent: {
    minHeight: '30px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: -2,

    '$notCommented &': {
      marginTop: 0,
    },
  },
  markNotes: {
    whiteSpace: 'pre-wrap',
    paddingBottom: '4px',
    maxWidth: '65ch',
  },
  tooltip: {
    alignSelf: 'center',
  },
});
