import React, { FC } from 'react';
import { MonthSelector } from '@shared/components/MonthSelector/MonthSelector';
import { useStyles } from './ExtendPeriodDatePicker.styles';
import { useDispatch } from 'react-redux';
import { setNextEvalDate } from '@modules/EvaluationModule/pages/EvalPage/redux';
import addDays from 'date-fns/addDays';
import { getDateToExtend } from '@shared/components/MonthSelector/helpers/getDateToExtend';

interface Props {
  nextEvalDate: string;
  currentEvalDate: string;
}
export const ExtendPeriodDatePicker: FC<Props> = ({ nextEvalDate, currentEvalDate }) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const onSetNextEvalDate = (newDate: Date) => {
    const formattedDate = getDateToExtend({
      selectedDate: newDate.toISOString(),
    });

    dispatch(setNextEvalDate(formattedDate));
  };

  return (
    <div className={styles.nextEvalLabel}>
      <span>Next evaluation:</span>
      <MonthSelector date={nextEvalDate} onChange={onSetNextEvalDate} minDate={addDays(new Date(currentEvalDate), 1)} />
    </div>
  );
};
