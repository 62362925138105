import { EvalPeerReviewData } from '@modules/EvaluationModule/interfaces/EvalPeerReviewData';
import { EvaluationMarkDetails } from '@modules/EvaluationModule/interfaces/EvaluationMarkDetails';
import { EvaluationOptionsData } from '@modules/EvaluationModule/interfaces/EvaluationOptionsData';
import { UserInfo } from '@shared/interfaces/UserInfo';
import classNames from 'classnames';
import { get } from 'lodash';
import { FC, useCallback, useMemo } from 'react';
import { UserEvalBlockWithMark } from '../UserEvalBlockWithMark';
import { useStyles } from './FeedbackOnQuestionBlock.styles';
import { Typography } from '@mui/material';

interface Props {
  questionTitle: string | null;
  questionName: string;
  _evaluateeFeedback?: EvaluationOptionsData | null;
  _evaluatorFeedback?: EvaluationOptionsData | null;
  evaluateeUser?: UserInfo;
  evaluatorUser?: UserInfo;
  withMark?: boolean;
  _peerReviews?: EvalPeerReviewData[] | null;
  _clientReviews?: EvalPeerReviewData[] | null;
}

export const FeedbackOnQuestionBlock: FC<Props> = ({
  withMark,
  _evaluateeFeedback,
  _evaluatorFeedback,
  questionName,
  questionTitle,
  evaluateeUser,
  evaluatorUser,
  _peerReviews,
  _clientReviews,
}) => {
  const styles = useStyles();

  const getFeedback = useCallback(
    (feedback: EvaluationOptionsData | null) => {
      if (withMark) {
        return get(feedback, questionName) as EvaluationMarkDetails;
      }

      return { mark: null, notes: get(feedback, questionName) };
    },
    [withMark, questionName]
  );

  const { evaluateeFeedback, evaluatorFeedback, peerReviews, clientReviews, count } = useMemo(() => {
    const feedbacks = {
      evaluateeFeedback:
        (_evaluateeFeedback?.isConfirmed && evaluateeUser && getFeedback(_evaluateeFeedback)) || undefined,
      evaluatorFeedback:
        (_evaluatorFeedback?.isConfirmed && evaluatorUser && getFeedback(_evaluatorFeedback)) || undefined,
      peerReviews: (_peerReviews ?? [])
        .map((x) => ({ ...x, feedback: getFeedback(x.feedback) }))
        .filter((x) => x.feedback.mark || x.feedback.notes),
      clientReviews: (_clientReviews ?? [])
        .map((x) => ({ ...x, feedback: getFeedback(x.feedback) }))
        .filter((x) => x.feedback.mark || x.feedback.notes),
    };

    feedbacks.peerReviews.sort((a, b) => {
      if (a.feedback.notes && !b.feedback.notes) return -1;
      if (!a.feedback.notes && b.feedback.notes) return 1;
      return 0;
    });

    const filteredFeedbacks = {} as typeof feedbacks & { count: number };
    if (feedbacks.evaluateeFeedback?.mark || feedbacks.evaluateeFeedback?.notes) {
      filteredFeedbacks.evaluateeFeedback = feedbacks.evaluateeFeedback;
    }

    if (feedbacks.evaluatorFeedback?.mark || feedbacks.evaluatorFeedback?.notes) {
      filteredFeedbacks.evaluatorFeedback = feedbacks.evaluatorFeedback;
    }

    filteredFeedbacks.peerReviews = feedbacks.peerReviews;
    filteredFeedbacks.clientReviews = feedbacks.clientReviews;
    filteredFeedbacks.count =
      (filteredFeedbacks.evaluateeFeedback ? 1 : 0) +
      (filteredFeedbacks.evaluatorFeedback ? 1 : 0) +
      filteredFeedbacks.peerReviews.length +
      filteredFeedbacks.clientReviews.length;

    return filteredFeedbacks;
  }, [_clientReviews, _evaluateeFeedback, _evaluatorFeedback, _peerReviews, evaluateeUser, evaluatorUser, getFeedback]);

  return (
    <div className={styles.root}>
      {questionTitle && (
        <>
          <div className={styles.title}>
            <div>{questionTitle}</div>
          </div>
          <div className={classNames(styles.columnFeedbackContainer)}>
            {count === 0 && <Typography color="text.secondary">No one commented</Typography>}
            {evaluateeFeedback && evaluateeUser && (
              <div className={styles.evalPrimaryParticipant}>
                <UserEvalBlockWithMark user={evaluateeUser} question={evaluateeFeedback} />
              </div>
            )}
            {evaluatorFeedback && evaluatorUser && (
              <div className={styles.evalPrimaryParticipant}>
                <UserEvalBlockWithMark user={evaluatorUser} question={evaluatorFeedback} />
              </div>
            )}
            {peerReviews &&
              peerReviews.length > 0 &&
              peerReviews.map((peerReview) => (
                <UserEvalBlockWithMark key={peerReview.id} user={peerReview.user} question={peerReview.feedback} />
              ))}
            {clientReviews &&
              clientReviews.length > 0 &&
              clientReviews.map((clientReview) => (
                <UserEvalBlockWithMark
                  key={clientReview.id}
                  user={clientReview.user}
                  question={clientReview.feedback}
                />
              ))}
          </div>
        </>
      )}
    </div>
  );
};
