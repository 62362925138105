import { getColorByFeedbackMark } from '@modules/EvaluationModule/helpers/getColorByFeedbackMark';
import React, { FC, useState } from 'react';
import { useStyles } from './SummaryTableHeader.styles';
import { Popup } from 'reactjs-popup';
import { EvaluationMark } from '@modules/EvaluationModule/enums/EvaluationMark';
import { ScalingBar } from '../../enums/ScalingBar';

interface Props {
  graphValues:
    | {
        [index: string]: number;
      }
    | undefined;
}

export const SummaryTableHeader: FC<Props> = ({ graphValues }) => {
  const styles = useStyles();
  const [scalingBarNumber, setScalingBarNumber] = useState(0);

  return (
    <div className={styles.tableHeader}>
      <div className={styles.tableHeaderTitle}>Marks</div>
      <div className={styles.graph}>
        {graphValues &&
          Object.entries(graphValues).map(([key, value]) => {
            const markPecentage = Math.round(
              (value /
                Object.values(graphValues)
                  .map((value) => value)
                  .reduce((a, b) => a + b, 0)) *
                100
            );
            !scalingBarNumber && markPecentage
              ? markPecentage > 70
                ? setScalingBarNumber(ScalingBar.Medium)
                : setScalingBarNumber(ScalingBar.High)
              : null;

            const markColor = getColorByFeedbackMark(key as EvaluationMark);
            return (
              <Popup
                key={key}
                on={['hover']}
                position="top left"
                className={styles.graphItemPopup}
                trigger={
                  <div className={styles.graphItem}>
                    <div
                      key={key}
                      className={styles.feedbackMarkIndicator}
                      style={{
                        width: `${markPecentage * scalingBarNumber}px`,
                        backgroundColor: markColor,
                      }}
                    ></div>
                    <span
                      style={{
                        color: markColor,
                      }}
                    >
                      {markPecentage}%
                    </span>
                  </div>
                }
              >
                <div
                  style={{
                    color: markColor,
                  }}
                >
                  {key}
                </div>
              </Popup>
            );
          })}
      </div>
    </div>
  );
};
