import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    marginBottom: '32px',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: COLORS.TEXT.PRIMARY,
    fontWeight: '500',
    marginBottom: 8,
  },
  columnFeedbackContainer: {
    background: COLORS.HIGHLIGHTS.NEUTRAL,
    borderRadius: 8,
    display: 'flex',
    flexWrap: 'wrap',
    rowGap: 12,
    padding: '16px',
    margin: '0 -16px',
  },
  evalPrimaryParticipant: {
    width: '100%',
  },
});
