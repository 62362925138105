import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  tabSwitcher: {
    display: 'flex',
    width: '100%',
    maxWidth: '1400px',
    margin: '0 auto',
  },
  link: {
    borderTopRightRadius: '8px',
    borderTopLeftRadius: '8px',
    marginRight: 4,
    whiteSpace: 'nowrap',
    textDecoration: 'none',
    fontSize: '14px',
    fontWeight: '400',
    color: COLORS.TEXT.PRIMARY,
    padding: '10px 16px 12px',
    transition: 'all 0.2s',

    '&:hover': {
      backgroundColor: COLORS.BRAND.HIGHLIGHT,
      zIndex: 2,
    },
  },
  activeLink: {
    backgroundColor: COLORS.BRAND.HIGHLIGHT,
    position: 'relative',
    boxShadow: '0 12px 12px 8px #ffffff',
  },
  button: {
    color: COLORS.TEXT.PRIMARY,
    borderRadius: 8,
    marginRight: 4,
    '&:hover': {
      backgroundColor: COLORS.BRAND.HIGHLIGHT,
    },
  },
  activeButton: {
    backgroundColor: COLORS.BRAND.HIGHLIGHT,
  },
});
