import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    paddingTop: 6,
  },
  tableRoot: {
    height: '100%',
    overflow: 'auto',
    borderSpacing: '0',
    borderCollapse: 'collapse',
  },
  title: {
    fontWeight: 500,
    fontSize: 14,
    marginBottom: 8,
  },
  tableHeader: {
    display: 'grid',
    gridTemplateColumns: 'repeat(15, 1fr)',
    alignItems: 'center',
    gridTemplateRows: '1fr',
    padding: '16px 24px',
    fontWeight: '400',
    fontSize: '14px',
    background: COLORS.HIGHLIGHTS.NEUTRAL,
    color: COLORS.TEXT.SECONDARY,
    [BREAKPOINTS.MOBILE]: {
      display: 'none',
    },
  },
  goalsTableHeader: {
    display: 'table-row',
    '& td': {
      padding: '16px',
    },
    '& td:first-child': {
      paddingLeft: '24px',
    },
    '& td:last-child': {
      paddingRight: '24px',
    },
    [BREAKPOINTS.MOBILE]: {
      display: 'none',
    },
  },
  goalItemSubheader: {
    padding: '16px 12px 4px',
  },
  goalsTableSmall: {
    maxWidth: 480,
    '& table': {
      width: '100%',
    },
  },
  goalsTableHeaderSmall: {
    background: 'none',
    borderBottom: `1px solid ${COLORS.DIVIDER}`,
    '& td:first-child': {
      paddingLeft: 12,
    },
    '& td:last-child': {
      paddingRight: 12,
      paddingLeft: 8,
    },
  },
  goalStatusProgress: {
    color: COLORS.ACTION.PRIMARY,
  },
  goalStatusAchieved: {
    color: COLORS.STATUS.SUCCESS,
  },
  goalsLoadMore: {
    display: 'block',
    padding: '16px 12px 18px',
    margin: -1,
    '&:hover': {
      backgroundColor: COLORS.HIGHLIGHTS.NEUTRAL,
      cursor: 'pointer',
    },
  },
  noGoals: {
    padding: '24px 12px 32px 32px',
    color: COLORS.HIGHLIGHTS.NEUTRAL,
  },
  emptyView: {
    background: COLORS.HIGHLIGHTS.NEUTRAL,
    borderRadius: 8,
    padding: '16px',
    margin: '0 0 0 -16px',
    display: 'flex',
    columnGap: 16,
    alignItems: 'center',
  },
  emptyViewTitle: {
    color: COLORS.TEXT.SECONDARY,
    marginBottom: 12,
  },
  loaderHolder: {
    maxWidth: 480,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 150,
  },
});
