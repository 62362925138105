import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    width: '100%',
    marginTop: 16,
    marginBottom: 24,
  },
  notesAndGoals: {
    flex: '1 auto',
  },
  halfPart: {
    maxWidth: '50%',
  },
  blockTitle: {
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '120%',
    margin: '0 0 2px',
    color: COLORS.TEXT.PRIMARY,
  },
  dateText: {
    color: COLORS.TEXT.SECONDARY,
    margin: '0 0 20px',
  },
  dateLink: {
    color: COLORS.ACTION.PRIMARY,
    textDecoration: 'underline',
    margin: '0 0 20px',
  },
  evalInfo: {
    color: COLORS.TEXT.PRIMARY,
    margin: '8px 0 24px',
    whiteSpace: 'pre-line',
  },
  noEvalInfo: {
    color: COLORS.TEXT.SECONDARY,
    background: COLORS.HIGHLIGHTS.NEUTRAL,
    borderRadius: 8,
    padding: '16px',
    margin: '4px 0 4px -16px',
  },
});
